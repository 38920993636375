<template>
	<div id="app-professional-data">
		<back-step
			v-if="false"
			:step-number="3"
		></back-step>
		<call-help></call-help>
		<app-stepper :step-number="3"></app-stepper>
		<div class="row flex-column align-items-center mainBox">
			<h2 class="col-11 col-xl-8 mb-4 text-center text-format-1">
				Cuéntanos un poco sobre tu educación y situación laboral ¡Ya no queda nada!
			</h2>
			<form class="d-flex flex-column align-items-center">
				<div class="row justify-content-start flex-column flex-md-row w-100 flex-wrap">
					<div
						class="form-group"
						v-if="!hideVerticalColective"
					>
						<label
							for="verticalSelected"
							:class="{ invalidForm: verticalSelectedError }"
						>
							*Rama de Estudios
						</label>
						<select
							v-model.trim="verticalSelected"
							class="form-control"
							:class="{ invalidInfoBox: verticalSelectedError }"
							id="selectVertical"
						>
							<option
								v-for="(option, index) in verticals"
								:key="index"
								:value="option"
							>
								{{ option.description }}
							</option>
						</select>
						<div class="space-error">
							<span
								v-if="verticalSelectedError"
								class="mt-2 invalidInfo help-block"
							>
								{{ verticalSelectedError }}
							</span>
						</div>
					</div>
					<div
						class="form-group"
						v-if="!hideVerticalColective"
					>
						<label
							for="collective"
							:class="{ invalidForm: collectiveSelectedError }"
						>
							*Titulación
						</label>
						<select
							v-model.trim="collectiveSelected"
							class="form-control"
							:class="{ invalidInfoBox: collectiveSelectedError }"
							id="selectCollective"
						>
							<option
								v-for="(option, index) in displayCollectives"
								:key="index"
								:value="option"
							>
								{{ option.description }}
							</option>
						</select>
						<div class="space-error">
							<span
								v-if="collectiveSelectedError"
								class="mt-2 invalidInfo help-block"
							>
								{{ collectiveSelectedError }}
							</span>
						</div>
					</div>
					<div class="form-group">
						<label
							for="employmentSituation"
							:class="{ invalidForm: employmentSituationError }"
						>
							*Situación Laboral
						</label>
						<select
							v-model.number="$v.employmentSituation.$model"
							id="employmentSituation"
							class="form-control"
							:class="{ invalidInfoBox: employmentSituationError }"
						>
							<option
								disabled
								value="undefined"
							></option>
							<option value></option>
							<option
								v-for="(combo, id) in COMBO_SITUACION_LABORAL"
								:key="id"
								:value="id"
							>
								{{ combo }}
							</option>
						</select>
						<div class="space-error">
							<span
								v-if="employmentSituationError"
								class="mt-2 invalidInfo help-block"
							>
								{{ employmentSituationError }}
							</span>
						</div>
					</div>
					<div
						class="form-group"
						v-if="employmentSituation === 1"
					>
						<label
							:class="{ invalidForm: cnaeActivityCodeError }"
							for="typeahead-basic"
						>
							*Actividad Profesional
						</label>

						<vue-bootstrap-typeahead
							ref="professionalActivity"
							v-model="preCNAE"
							:class="{ invalidInfoBox: cnaeActivityCodeError }"
							:data="Object.keys(COMBO_CNAE)"
							:serializer="(id) => COMBO_CNAE[id]"
							@hit="$v.cnaeActivityCode.$model = $event"
							@focusout.native="validateCombo"
						/>

						<div class="space-error">
							<span
								v-if="cnaeActivityCodeError"
								class="mt-2 invalidInfo help-block"
							>
								{{ cnaeActivityCodeError }}
							</span>
						</div>
					</div>
					<div
						class="form-group"
						v-if="employmentSituation === 2"
					>
						<label
							:class="{ invalidForm: cnoProfessionCodeError }"
							for="typeahead-basic"
						>
							*Profesión
						</label>

						<vue-bootstrap-typeahead
							ref="profession"
							v-model="preCNO"
							:class="{ invalidInfoBox: cnoProfessionCodeError }"
							:data="Object.keys(COMBO_CNO)"
							:serializer="(id) => COMBO_CNO[id]"
							@hit="$v.cnoProfessionCode.$model = $event"
							@focusout.native="validateCombo"
						/>

						<div class="space-error">
							<span
								v-if="cnoProfessionCodeError"
								class="mt-2 invalidInfo help-block"
							>
								{{ cnoProfessionCodeError }}
							</span>
						</div>
					</div>
					<div
						data-testid="company-name"
						class="form-group"
						v-if="employmentSituation === 1 || employmentSituation === 2"
					>
						<label
							:class="{ invalidForm: companyNameError }"
							for="nombreEmpr"
						>
							*Nombre de la empresa
						</label>
						<input
							class="form-control"
							id="nombreEmpr"
							:class="{ invalidInfoBox: companyNameError }"
							v-model.trim="companyName"
							@blur="$v.companyName.$touch()"
							placeholder
							type="text"
							maxlength="100"
						/>
						<div class="space-error">
							<span
								v-if="companyNameError"
								class="mt-2 invalidInfo help-block"
							>
								{{ companyNameError }}
							</span>
						</div>
					</div>
					<div class="form-group">
						<label
							:class="{ invalidForm: originOfFundsError }"
							for="originOfFunds"
						>
							*Origen fondos
						</label>
						<select
							id="originOfFunds"
							class="form-control"
							:class="{ invalidInfoBox: originOfFundsError }"
							v-model.trim="$v.originOfFunds.$model"
						>
							<option
								disabled
								value="undefined"
							></option>
							<option value></option>
							<option
								v-for="(combo, id) in COMBO_ORIGEN_INGRESOS"
								:key="id"
								:value="id"
							>
								{{ combo }}
							</option>
						</select>
						<div class="space-error">
							<span
								v-if="originOfFundsError"
								class="mt-2 invalidInfo help-block"
							>
								{{ originOfFundsError }}
							</span>
						</div>
					</div>

					<div class="form-group">
						<label
							:class="{ invalidForm: anualIncomeError }"
							for="anualIncome"
						>
							*Ingresos anuales
						</label>
						<select
							id="anualIncome"
							v-model.trim="$v.anualIncome.$model"
							class="form-control"
							:class="{ invalidInfoBox: anualIncomeError }"
						>
							<option
								disabled
								value="undefined"
							></option>
							<option value></option>
							<option
								v-for="(combo, id) in COMPUTED_COMBO_INGRESOS"
								:key="id"
								:value="id"
							>
								{{ combo }}
							</option>
						</select>
						<div class="space-error">
							<span
								v-if="anualIncomeError"
								class="mt-2 invalidInfo help-block"
							>
								{{ anualIncomeError }}
							</span>
						</div>
					</div>
				</div>
				<div class="confirm-button-group">
					<button
						class="button-confirm my-5"
						type="submit"
						:disabled="isButtonDisabled"
						data-testid="confirm-button"
						@click.prevent="submit"
					>
						CONTINUAR
					</button>
				</div>
				<app-mobile-call></app-mobile-call>
			</form>
		</div>
	</div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap';
import CallHelp from '@/components/call-help';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';
import { typeahead } from '@/utils/validators';
import { mapErrorFields } from '@/utils/helpers';
import {
	COMBO_SITUACION_LABORAL,
	COMBO_INGRESOS,
	COMBO_ORIGEN_INGRESOS,
	COMBO_CNO,
	COMBO_CNAE,
} from '@/db';
import BackStep from '@/components/back-step';
import { mapState } from 'vuex';

export default {
	name: 'app-professional-data',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
		VueBootstrapTypeahead,
		BackStep,
	},

	data() {
		return {
			COMBO_SITUACION_LABORAL,
			COMBO_ORIGEN_INGRESOS,
			COMBO_INGRESOS,
			COMBO_CNO,
			COMBO_CNAE,
			employmentSituation: 2,
			preCNO: '',
			preCNAE: '',
			cnoProfessionCode: '',
			cnaeActivityCode: '',
			companyName: '',
			originOfFunds: '',
			anualIncome: '',
			isButtonDisabled: false,
			verticals: null,
			collectives: null,
			verticalSelected: null,
			collectiveSelected: null,
		};
	},

	validations: {
		employmentSituation: {
			required,
		},
		cnoProfessionCode: {
			// eslint-disable-next-line func-names
			required: requiredIf(function () {
				return this.employmentSituation === 2;
			}),
			cno: typeahead('preCNO', 'COMBO_CNO'),
		},
		cnaeActivityCode: {
			// eslint-disable-next-line func-names
			required: requiredIf(function () {
				return this.employmentSituation === 1;
			}),
			cnae: typeahead('preCNAE', 'COMBO_CNAE'),
		},
		companyName: {
			// eslint-disable-next-line func-names
			required: requiredIf(function () {
				return this.employmentSituation === 1 || this.employmentSituation === 2;
			}),
			companyNameValidator: helpers.regex('companyNameValidator', /^[a-zA-Z0-9À-ž\s&ºª-]*$/),
		},
		originOfFunds: {
			required,
		},
		anualIncome: {
			required,
		},
		verticalSelected: {
			required,
		},
		collectiveSelected: {
			required,
		},
	},

	computed: {
		...mapState('onboarding', ['clientInformation']),

		...mapErrorFields([
			'employmentSituation',
			'cnoProfessionCode',
			'cnaeActivityCode',
			'companyName',
			'originOfFunds',
			'anualIncome',
			'verticalSelected',
			'collectiveSelected',
		]),
		// eslint-disable-next-line no-shadow
		COMPUTED_COMBO_INGRESOS({ COMBO_INGRESOS, employmentSituation }) {
			return Object.fromEntries(
				Object.entries(COMBO_INGRESOS).filter(([id]) => id !== 0 || employmentSituation > 2)
			);
		},

		displayCollectives() {
			return this.collectives;
		},

		hideVerticalColective() {
			const { vertical, collective } = this.$store.state.app;

			return vertical && collective;
		},
	},

	watch: {
		employmentSituation() {
			// Reset the profession / professionalActivity
			// text field after change the employmentSituation
			this.cnoProfessionCode = '';
			this.cnaeActivityCode = '';
			this.$v.$reset();
			if (this.$refs.profession) {
				this.preCNO = '';
				this.$refs.profession.inputValue = '';
			}
			if (this.$refs.professionalActivity) {
				this.preCNAE = '';
				this.$refs.professionalActivity.inputValue = '';
			}
		},
	},

	methods: {
		validateCombo() {
			this.$v.cnoProfessionCode.$touch();
			this.$v.cnaeActivityCode.$touch();
		},
		submit() {
			const { $v, $router } = this;

			const {
				employmentSituation,
				cnoProfessionCode,
				cnaeActivityCode,
				companyName,
				originOfFunds,
				anualIncome,
				verticalSelected,
				collectiveSelected,
			} = this;

			$v.$touch();

			if (!$v.$invalid) {
				this.isButtonDisabled = true;
				this.$store
					.dispatch('onboarding/saveProcess', {
						employmentSituation: {
							id: employmentSituation,
							desc: COMBO_SITUACION_LABORAL[employmentSituation],
						},
						professionCnoCode: cnoProfessionCode,
						activityCnaeCode: cnaeActivityCode,
						companyName,
						originOfFunds: {
							id: originOfFunds,
							desc: COMBO_ORIGEN_INGRESOS[originOfFunds],
						},
						annualIncome: {
							id: anualIncome,
							desc: COMBO_INGRESOS[anualIncome],
						},
						vertical: verticalSelected.id,
						collective: collectiveSelected.id,
					})
					.then(() => $router.push('app-gdpr-consent'))
					.catch(() => {})
					.finally(() => {
						this.isButtonDisabled = false;
					});
			}
		},

		getKeyByValue(obj, value) {
			return Object.keys(obj).find((key) => obj[key] === value);
		},

		recoverPendingData() {
			const { employmentSituation, collective, vertical } = this.clientInformation;

			this.collectiveSelected = collective;
			this.verticalSelected = vertical;

			this.cnoProfessionCode = employmentSituation.profession?.id;
			this.preCNO = employmentSituation.profession?.name;
			this.companyName = employmentSituation.company;

			this.originOfFunds = this.getKeyByValue(
				this.COMBO_ORIGEN_INGRESOS,
				employmentSituation.fundsOrigin
			);
			this.anualIncome = this.getKeyByValue(
				this.COMBO_INGRESOS,
				employmentSituation.annualIncome
			);
		},

		async getCollectives() {
			if (!this.verticalSelected) {
				return;
			}

			const { data } = await this.$store
				.dispatch('onboarding/getCollectives', this.verticalSelected.id)
				.catch();

			return data.data;
		},

		async getVerticals() {
			const { data } = await this.$store.dispatch('onboarding/getVerticals').catch();
			return data.data;
		},
	},

	async mounted() {
		const clientHasInformation = this.clientInformation != null;
		const clientHasProfession = this.clientInformation?.employmentSituation?.profession != null;

		if (clientHasInformation && clientHasProfession) {
			this.recoverPendingData();
		}
		const { vertical, collective } = this.$store.state.app;
		const arrayVertical = await this.getVerticals();

		if (vertical) {
			this.verticals = Array.of(arrayVertical.find((item) => item.id.toString() === vertical));
			if (!this.verticals[0]) {
				this.verticals = arrayVertical;
			} else {
				this.verticalSelected = this.verticals[0];
			}
		} else {
			this.verticals = arrayVertical;
		}

		if (collective) {
			const arrayCollectives = await this.getCollectives();

			if (!arrayCollectives) {
				return;
			}

			this.collectives = Array.of(
				arrayCollectives.find((item) => item.id.toString() === collective)
			);
			this.collectiveSelected = this.collectives[0];
		}
	},

	watch: {
		verticalSelected(value) {
			if (value) {
				this.getCollectives().then((data) => {
					this.collectives = data;
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form-group {
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 1rem;
	flex-grow: 1;
	width: 50%;
}
.mainBox {
	margin: 20px 0 0;
}
label {
	white-space: nowrap;
}
form {
	width: 100%;
}
option {
	color: #113456 !important;
	font-family: 'Poppins', sans-serif !important;
	background: #fff;
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	.form-group {
		width: 100%;
	}
}
</style>
